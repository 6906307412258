<template>
  <div class="wbs-code-pool">
    <div :class="['title-n-search', (idSearched ? '' : 'title-n-search-begin')]">
      <div class="title mt-4">
        WBS CODE POOL
      </div>
      <div class="search">
        <v-text-field
          v-model="idInput"
          solo
          placeholder="Please enter Opportunity Number / Project ID / WBS Parent"
          @change="changeSearch"
          @focus="focusSearch"
          @blur="blurSearch">
          <template v-slot:prepend-inner>
            <div
              v-if="isSearching"
              style="width:24px;height:24px;padding-top:3px;">
              <v-progress-circular
                :width="2"
                color="grey"
                indeterminate
                :size="18"
              ></v-progress-circular>
            </div>
            <v-icon v-else @click="changeSearch">mdi-magnify</v-icon>
          </template>
          <template v-slot:append>
            <v-btn
              :disabled="!idInput"
              small
              dark
              :color="section_color"
              @click="search"
              >
              Search
              </v-btn>
          </template>
        </v-text-field>
      </div>
    </div>
    <div 
      v-if="idSearched"
      class="wbs-code-pool-result mt-3">
      <div v-if="cards.length" class="result-title">
        Results
      </div>
      <div v-else class="result-no-item">
        No Result Found
      </div>
      <div 
        v-for="(card, ci) in cards" 
        :key="'wbs_card_' + ci"
        class="mt-3 pb-2">
        <v-card class="elevation-2">
          <div style="display:flex;">
            <div class="wbs" style="width:10px;border-radius: 3px 0 0 3px;">
            </div>
            <div class="card-main-content" style="flex:1;">
              <v-card-title class="pt-3 pb-1" style="font-size:15px;">
                <b>{{ card.opp_id }} - {{ card.opp_name }}</b>
              </v-card-title>
              <v-card-text class="result-table pt-4 pb-6 px-6">
                <v-layout wrap>
                  <v-flex pt-1 pb-1 pl-2 pr-2 xs12 lg4>
                    <div style="display:flex;">
                      <div :class="$vuetify.breakpoint.lgAndUp ? 'banner-lg-1' : 'banner'">
                        Account Name:
                      </div>
                      <div>
                        {{ card.account }}
                      </div>
                    </div>
                  </v-flex>
                  <v-flex pt-1 pb-1 pl-2 pr-2 xs12 lg5>
                    <div style="display:flex;">
                      <div :class="$vuetify.breakpoint.lgAndUp ? 'banner-lg-2' : 'banner'">
                        Sold To Party:
                      </div>
                      <div>
                        {{ card.sap }}
                      </div>
                    </div>
                  </v-flex>
                  <v-flex pt-1 pb-1 pl-2 pr-2 xs12 lg3>
                    <div style="display:flex;">
                      <div :class="$vuetify.breakpoint.lgAndUp ? 'banner-lg-3' : 'banner'">
                        Contract Amount:
                      </div>
                      <div :class="$vuetify.breakpoint.lgAndUp ? 'value-number' : ''">
                        {{ toThousandsInt(card.contr_amount) }} {{ card.contr_currency }}
                      </div>
                    </div>
                  </v-flex>
                  <v-flex pt-1 pb-1 pl-2 pr-2 xs12 lg4>
                    <div style="display:flex;">
                      <div :class="$vuetify.breakpoint.lgAndUp ? 'banner-lg-1' : 'banner'">
                        Project ID:
                      </div>
                      <div>
                        {{ card.proj_id }}
                      </div>
                    </div>
                  </v-flex>
                  <v-flex pt-1 pb-1 pl-2 pr-2 xs12 lg5>
                    <div style="display:flex;">
                      <div :class="$vuetify.breakpoint.lgAndUp ? 'banner-lg-2' : 'banner'">
                        Quali/Quanti:
                      </div>
                      <div>
                        {{ card.ql_o_qt }}
                      </div>
                    </div>
                  </v-flex>
                  <v-flex pt-1 pb-1 pl-2 pr-2 xs12 lg3>
                    <div style="display:flex;">
                      <div :class="$vuetify.breakpoint.lgAndUp ? 'banner-lg-3' : 'banner'">
                        Project Amount:
                      </div>
                      <div :class="$vuetify.breakpoint.lgAndUp ? 'value-number' : ''">
                        {{ toThousandsInt(card.proj_amount) }} {{ card.proj_currency }}
                      </div>
                    </div>
                  </v-flex>
                  <v-flex pt-4 pb-1 pl-2 pr-2 xs12>
                    <div style="display:flex;align-items:center;text-align:right;">
                      <div style="flex:1"></div>
                      <wbs-tooltip-intro />
                      <div 
                        class="wbs--text"
                        style="font-weight:bold;font-size:15px;">
                        WBS Parent - {{ card.wbs_code }}
                      </div>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { section_color } from '@/config'
import { mapActions, mapGetters } from 'vuex'
import { xPost } from '@/functions/http/axios'

import wbsTooltipIntro from '@/components/app/wbs/tooltip-intro'

import { toThousands } from '@/functions/maths'

export default {
  components: {
    wbsTooltipIntro
  },
  data () {
    return {
      // params
      isSearchFocused: false,
      isSearching: false,

      // props
      idInput: null,
      idSearched: null,

      // data
      cards: []
    }
  },
  computed: {
    // vuex
    ...mapGetters('user', [
      'loginStatus'
    ]),

    // params
    section_color () {
      return section_color['wbs']
    }
  },
  methods: {
    // vuex
    ...mapActions('app', ['setMainTitle']),

    // function
    focusSearch () {
      this.isSearchFocused = true
    },
    blurSearch () {
      this.isSearchFocused = false
    },
    changeSearch () {
      if (this.isSearchFocused && this.idInput && (this.idInput != this.idSearched)) {
        this.search()
      }
    },
    async search () {
      this.isSearching = true
      try {
        const res = await xPost(
          'wbs',
          'getpoollist',
          {
            loginStatus: this.loginStatus,
            i: this.idInput
          }
        )
        if (res.data.status === 200) {
          let data = []
          if (res.data.data_nea) {
            data = data.concat(res.data.data_nea.map(el => ({
              opp_id: el.OpportunityId || 'NA',
              opp_name: el.OpportunityName || '',
              account: el.AccName || '-',
              sap: el.SoldToParty || '-',
              proj_id: el.ProjectId || '-',
              ql_o_qt: el.QL_QT || '-',
              contr_amount: parseFloat(el.OppAmount) || 0,
              contr_currency: el.Currency,
              proj_amount: parseFloat(el.ProjAmount) || 0,
              proj_currency: el.Currency,
              wbs_code: el.WbsCode
            })))
          }
          if (res.data.data_exc) {
            data = data.concat(res.data.data_exc.map(el => ({
              opp_id: el.OppId || 'NA',
              opp_name: el.OppName || '',
              account: el.AccName || '-',
              sap: el.SoldToParty || '-',
              proj_id: el.ProjectId || '-',
              ql_o_qt: el.QloQt || '-',
              contr_amount: parseFloat(el.OppAmount) || 0,
              contr_currency: el.OppCurrency,
              proj_amount: parseFloat(el.ProjAmount) || 0,
              proj_currency: el.ProjCurrency,
              wbs_code: el.WbsCode
            })))
          }
          this.cards = data
        } else {
          try {
            if (res.data.message === 'Authorize Failed') {
              this.$router.push('/login')
              return []
            }
          } catch (e) {}
          throw ''
        }
        this.idSearched = this.idInput
      } catch (err) {
        console.log('Error [get pool list]')
        // console.log(err)
      }
      this.isSearching = false
    },

    // tool
    toThousandsInt (n) {
      return toThousands(Math.round(n))
    }
  },
  async created () {
    this.setMainTitle('CI WBS Code Pool')
  }
}
</script>

<style lang="sass">
  .wbs-code-pool
    
    .title-n-search
      text-align: center
    
      .title
        font-family: 'Tahoma' !important
        font-size: 36px !important
      

      .search
        margin: 36px auto 0
        max-width: 600px
      

      &-begin
        padding-top: calc(50vh - 240px)
      
    

    .wbs-code-pool-result
      margin: 0 auto
      max-width: 1200px

      .result-title
        text-align: left
      

      .result-no-item
        padding-top: 90px
      

      .result-table
        text-align: left

        .flex
          text-align: left
        

        .banner
          width: 140px
          min-width: 140px
          max-width: 140px
        

        .banner-lg-1
          width: 100px
          min-width: 100px
          max-width: 100px
        

        .banner-lg-2
          width: 90px
          min-width: 90px
          max-width: 90px
        

        .banner-lg-3
          width: 115px
          min-width: 115px
          max-width: 115px
        

        .value-number
          flex-grow: 1
          text-align: right
        
      
    
  
</style>
